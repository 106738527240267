import type { TagFieldProps } from '@venncity/block';
import { TagField } from '@venncity/block';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useAsyncList } from 'react-stately';

interface AsyncTagFieldProps<T extends object> extends Omit<TagFieldProps<T>, 'items'> {
  resource: string;
  filterItems?: (item: T) => boolean;
}

export default function AsyncTagField<T extends object>({
  resource,
  children,
  defaultSelectedItems,
  getItemId,
  filterItems = () => true,
  ...props
}: AsyncTagFieldProps<T>) {
  const list = useAsyncList<T>({
    async load({ filterText }) {
      const { data } = await axios.get(`/resources/${resource}`, {
        params: { q: filterText },
      });
      return { items: data?.filter(filterItems) || [] };
    },
  });

  return (
    <TagField
      {...props}
      defaultSelectedItems={defaultSelectedItems}
      getItemId={getItemId}
      items={list.items}
      onInputChange={debounce(list.setFilterText, 400, {
        maxWait: 1000,
        leading: false,
        trailing: true,
      })}>
      {children}
    </TagField>
  );
}
